<template>
    <div class="register-page">
        <div class="register-container">
            <h1>Crear una Cuenta</h1>
            <p class="register-text">{{ $t('text_preferredLanguage', 'Join Us Today') }}</p>
            <form @submit.prevent="handleRegister">
                <div class="form-group">
                    <input v-model="username" type="text" :placeholder="$t('username_placeholder', 'username')" required />
                </div>
                <div class="form-group">
                    <input v-model="email" type="email" :placeholder="$t('email_placeholder', 'email')" required />
                </div>
                <div class="form-group">
                    <input v-model="password" type="password" :placeholder="$t('password_placeholder', 'password')" required />
                </div>
                <div class="form-group">
                    <input v-model="firstName" type="text" :placeholder="$t('firstName_placeholder', 'firstName')" required />
                </div>
                <div class="form-group">
                    <input v-model="lastName" type="text" :placeholder="$t('lastName_placeholder', 'lastName')" required />
                </div>
                <div class="form-group">
                    <select class="form-control" v-model="preferredLanguage" required>
                        <option disabled value="">{{ $t('label_preferredLanguage') }}</option>
                        <option v-for="(data, locale) in languages" :key="locale" :value="locale">
                            <img :src="data.flag" :alt="data.language" class="flag-icon" /> {{ data.language }}
                        </option>
                    </select>
                </div>
                <button type="submit" class="register-button">{{ $t('register', 'Register') }}</button>
                <span @click="changeSection('home')" class="cancel-button">{{ $t('cancel', 'cancel') }}</span>
            </form>
            <p v-if="responseMessage" :class="responseClass">{{ responseMessage }}</p>

        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import axios from 'axios';
import languages from '../utils/languages.json';
import config from '../config';

const username = ref('');
const email = ref('');
const password = ref('');
const firstName = ref('');
const lastName = ref('');
const preferredLanguage = ref('');
const responseMessage = ref('');
const responseClass = ref('');

const changeSection = inject('changeSection');

const handleRegister = async () => {
    responseMessage.value = ''; // Reset message before request
    responseClass.value = '';   // Reset class

    try {
        const response = await axios.post(`${config.apiHost}/api/auth/register`, {
            username: username.value,
            email: email.value,
            password: password.value,
            firstName: firstName.value,
            lastName: lastName.value,
            preferredLanguage: preferredLanguage.value
        });

        if (response.status === 201) {
            responseMessage.value = '¡Registro exitoso! Bienvenido a la plataforma.';
            responseClass.value = 'success-message';
            // Opcional: limpiar el formulario después del registro
            username.value = '';
            email.value = '';
            password.value = '';
            firstName.value = '';
            lastName.value = '';
            preferredLanguage.value = '';
            // go to login page
            changeSection('login');
        }
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    responseMessage.value = 'Datos de entrada inválidos. Verifica la información.';
                    break;
                case 409:
                    responseMessage.value = 'El nombre de usuario o correo electrónico ya existen.';
                    break;
                case 500:
                    responseMessage.value = 'Error interno del servidor. Inténtalo nuevamente más tarde.';
                    break;
                default:
                    responseMessage.value = 'Ocurrió un error. Inténtalo nuevamente.';
            }
        } else {
            responseMessage.value = 'No se pudo conectar con el servidor.';
        }
        responseClass.value = 'error-message';
    }
};
</script>

<style scoped>
.register-page {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    color: #fff;
    font-family: 'Arial', sans-serif;
}

.register-container {
    text-align: center;
    max-width: 500px;
    padding: 20px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.5);
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #ffdc62;
}

.register-text {
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    outline: none;
    font-size: 1rem;
}

.register-button {
    background-color: #ffdc62;
    color: #333;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-button:hover {
    background-color: #ffd700;
}

.success-message {
    color: #4caf50;
    margin-top: 20px;
}

.error-message {
    color: #f44336;
    margin-top: 20px;
}

.cancel-button {
    background-color: #d8d8d8;
    color: #333;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.cancel-button:hover {
    background-color: #aaaaaa;
}
</style>