// main.js
import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router';  // Importa el router que configuraste
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/js/bootstrap.bundle.js';

const app = createApp(App)
app.use(router);  // Usa Vue Router en tu aplicación
app.use(i18n)
app.mount('#app')