<template>
  <!-- TopBox -->
  <header>
    <!-- Barra de navegación superior -->
    <nav class="navbar">
      <div class="navbar-left">
        <span class="navbar-brand">{{ $t('app_name') }}</span>
        <span
          class="navbar-links"
          v-for="(section, key) in navbarSites"
          :key="key"
        >
          <span @click="changeSection(key)">{{ section.title }}</span>
        </span>
      </div>
      <div class="navbar-right dropdown">
        <span
          v-if="!isLoggedIn"
          class="navbar-links"
          @click="changeSection('login')"
          >{{ $t('login') }}</span
        >
        <div v-else class="usermenu">
          <div
            class="dropdown-toggle"
            id="userDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="cursor: pointer"
          >
            <span class="user-initial">
              {{ username.charAt(0).toUpperCase() }}
            </span>
          </div>
          <ul
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="userDropdown"
          >
            <li>
              <span class="dropdown-item"
                >{{ $t('hello') }} {{ username }}</span
              >
            </li>
            <li>
              <button @click="logout" class="dropdown-item">
                {{ $t('logout') }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!-- ContentBox -->
  <main>
    <HomePage v-if="siteSectionActive === 'home'" />
    <RegisterPage v-if="siteSectionActive === 'register' && !isLoggedIn" />
    <ChatsPage v-if="siteSectionActive === 'chats'" />
    <PasswordDialog
      v-if="siteSectionActive === 'login' && !isLoggedIn"
      @authenticated="handleAuthentication"
    />
    <div
      v-if="siteSectionActive === 'chatWith' && isLoggedIn"
      class="chat-container"
    >
      <!-- Ventana de mensajes -->
      <div class="view-container">
        <span class="chactCon">{{ $t('chatCon') }} {{ chatWith.username }}</span>
        <div
          v-if="callStatus === 'calling' || callStatus === 'connected'"
          class="video-window"
        >
          <video id="remoteVideo" ref="remoteVideo" autoplay playsinline :class="{ portrait: isPortrait, landscape: !isPortrait }" @dblclick="toggleOrientation"></video>
          <video id="localVideo" ref="localVideo" autoplay muted></video>
        </div>
        <div class="view-message">
          <div
            class="messages-window"
            ref="messagesWindow"
            :class="{ 'opacity-gradient': callStatus === 'calling' || callStatus === 'connected', 'half-size': callStatus === 'calling' || callStatus === 'connected' }"
            @click="removeGradient"
            @focusin="removeGradient"
            @focusout="addGradient"
            tabindex="0"
          >
            <div
              v-for="message in messages"
              :key="message._id"
              :class="messageClass(message)"
            >
              <p>{{ message.originalText }}</p>
              <hr />
              <p>{{ message.translatedText }}</p>
            </div>
          </div>
        </div>
        <!-- Barra de entrada -->
        <div class="input-bar">
          <!-- Nuevo botón para solicitar llamada -->
          <button @click="toggleCall" class="btn me-2" :class="callButtonStyle()">
            <i class="fas fa-phone"></i>
          </button>

          <input
            v-model="newMessage"
            @keyup.enter="sendMessage"
            type="text"
            class="form-control"
            :placeholder="$t('send_message_placeholder')"
            @focus="removeGradient"
            @blur="addGradient"
          />
          <button @click="sendMessage" class="btn btn-primary">
            <i class="fas fa-paper-plane"></i>
          </button>
        </div>
        <!-- Vista de llamada entrante -->
        <IncomingCallView
          v-if="callStatus === 'incomingCall'"
          :chatWithname="chatWith.username || 'Unknown'"
          @accept="acceptCall"
          @reject="rejectCall"
        />
        <!-- Vista de llamada conectada -->
        <ConnectedCallView
          v-if="callStatus === 'connected'"
          @end="handleEndCall"
        />
      </div>
    </div>
  </main>
  <!-- BottomBox -->
  <footer v-if="siteSectionActive != 'chatWith'">
    <div class="footer-container">
      <span class="footer-text">{{ $t('text_footer') }}</span>
    </div>
  </footer>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
  provide,
  onBeforeMount,
} from 'vue';
import PasswordDialog from './components/PasswordDialog.vue';
import { io } from 'socket.io-client';
import i18n from './i18n';
import config from './config';
import IncomingCallView from './components/IncomingCallView.vue';
import ConnectedCallView from './components/ConnectedCallView.vue';

// Páginas web
import HomePage from './components/HomePage.vue';
import RegisterPage from './components/RegisterPage.vue';
import ChatsPage from './components/ChatsPage.vue';
import router from './router';

const messages = ref([]);
provide('messages', messages);
const newMessage = ref('');
let socket = null;
const messagesWindow = ref(null); // Ref para el elemento messages-window
const token = ref(null);
provide('token', token);
const myself = ref(null);
provide('myself', myself);
const room = ref(null);
const callStatus = ref('disconnect'); // Estado de la llamada

// Sección de chat
const chatWith = ref(null);
provide('chatWith', chatWith);
const callerData = ref({});
const isCaller = ref(false);

// Videollamada
const localVideo = ref(null);
const remoteVideo = ref(null);
const localStream = ref(null);
const peerConnection = ref(null);
const iceServers = {
  iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
}; // Servidor STUN
const isPortrait = ref(true);

function toggleOrientation() {
  isPortrait.value = !isPortrait.value;
}
let webRTCListenersSetUp = false; // Flag para evitar múltiples configuraciones de listeners

// Secciones de la página web
const isLoggedIn = ref(false);
provide('isLoggedIn', isLoggedIn); // Hacer disponible isLoggedIn
const siteSectionsObj = {
  home: { title: 'Home', path: '/', inNavbar: true },
  about: { title: 'About', path: '/about', inNavbar: false },
  contact: { title: 'Contact', path: '/contact', inNavbar: false },
  login: { title: 'Login', path: '/login', inNavbar: false },
  signup: { title: 'Signup', path: '/signup', inNavbar: false },
  chats: { title: 'Chats', path: '/chats', inNavbar: true },
  chatWith: { title: 'Chat with', path: '/chatWith', inNavbar: false },
  register: { title: 'Register', path: '/register', inNavbar: false },
};
const siteSections = ref(siteSectionsObj);

const navbarSites = Object.fromEntries(
  Object.entries(siteSectionsObj).filter(
    ([__k, section]) => section.inNavbar // eslint-disable-line no-unused-vars
  )
);
const siteSectionActive = ref(
  localStorage.getItem('siteSectionActive') || 'home'
);
provide('siteSectionActive', siteSectionActive); // Hacer disponible siteSectionActive
provide('siteSections', siteSections); // Hacer disponible siteSections
const changeSection = (section) => {
  localStorage.setItem('siteSectionActive', section);
  siteSectionActive.value = section;
  if (siteSections.value[section])
    window.history.pushState(null, '', siteSections.value[section].path);
};
provide('changeSection', changeSection); // Hacer disponible changeSection

const username = computed(() => {
  return myself.value ? myself.value.username : 'Guest';
});

// Manejo de mensajes
function messageClass(message) {
  if (typeof message.from === 'string') {
    return message.from === myself.value._id ? 'message sent' : 'message received';
  } else {
    return message.from._id === myself.value._id ? 'message sent' : 'message received';
  }
}

async function sendMessage() {
  if (newMessage.value.trim() === '') return;

  if (!chatWith.value) {
    console.error('ID del otro usuario no definido');
    return;
  }

  // Enviar mensaje al servidor
  if (socket && socket.connected) {
    socket.emit('sendMessage', {
      roomId: room.value.uniqid,
      fromUserId: myself.value._id,
      toUserId: chatWith.value.username,
      originalText: newMessage.value,
      sourceLanguage: myself.value.language,
      targetLanguage: chatWith.value.language,
    });
  } else {
    console.error('Socket.IO no está conectado');
  }

  newMessage.value = '';
}

async function handleAuthentication(isRefresh = true) {
  // revisar si el token JWT todavía es valido usando endpoint
  token.value = localStorage.getItem('token');

  if(isRefresh){
    const response = await fetch(`${config.apiHost}/api/users/me`, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });
    if (response.ok) {
      isLoggedIn.value = true;
      myself.value = JSON.parse(localStorage.getItem('myself'));
      chatWith.value = JSON.parse(localStorage.getItem('chatWith'));
      
      // Inicializar Socket.IO
      await initSocketIO();
    } else {
      // trigger logout
      console.log('trigger logout');
      //logout();
    }
  }else{
    isLoggedIn.value = true;
    myself.value = JSON.parse(localStorage.getItem('myself'));
    chatWith.value = JSON.parse(localStorage.getItem('chatWith'));
    // Inicializar Socket.IO
    await initSocketIO();
  }
}

async function initSocketIO() {
  const token = localStorage.getItem('token');

  if (!socket) {
    socket = io(config.apiHost, {
      auth: {
        token: token,
      },
    });

    socket.on('connect', async () => {
      console.log('Conectado a Socket.IO');
    });

    socket.on('newMessage', (data) => {
      messages.value.push(data);
      scrollToBottom();
    });

    socket.on('connect_error', (err) => {
      console.error('Error de conexión:', err.message);
    });
  }

  socket.on('callUser', (data) => {
    console.log('Llamada entrante de otro usuario');
    callStatus.value = 'incomingCall';
    callerData.value = data.from;
  });

  // Configurar listeners de WebRTC
  setupWebRTCListeners();
}

// Configurar listeners de WebRTC para eventos entrantes
function setupWebRTCListeners() {
  console.log('Configurando listeners de WebRTC...');
  if (webRTCListenersSetUp) {
    console.log('Listeners de WebRTC ya configurados.');
    return;
  }
  webRTCListenersSetUp = true;

  if (!socket) {
    console.error('Socket no está inicializado.');
    return;
  }

  socket.on('offer', async (data) => {
    console.log('Oferta recibida:', data);
    if (!peerConnection.value) {
      await setupPeerConnection();
    }
    await handleOffer(data.offer);
  });

  socket.on('answer', async (data) => {
    console.log('Respuesta recibida:', data);
    if (peerConnection.value) {
      await handleAnswer(data.answer);
    }
  });

  socket.on('ice-candidate', async (data) => {
    //console.log('Candidato ICE recibido:', data);
    if (peerConnection.value && data.candidate) {
      await handleICECandidate(data.candidate);
    }
  });

  socket.on('callAccepted', async () => {
    console.log('La llamada ha sido aceptada por el otro usuario.');
    callStatus.value = 'connected';
    await setupConnectedCall();
  });

  socket.on('userBusy', () => {
    console.log('El otro usuario está ocupado.');
    callStatus.value = 'disconnect';
    endCall();
  });

  socket.on('error', (error) => {
    console.error('Error en la llamada:', error);
    callStatus.value = 'disconnect';
    endCall();
  });

  socket.on('endCall', () => {
    console.log('El otro usuario ha finalizado la llamada.');
    callStatus.value = 'disconnect';
    endCall();
  });

  // Manejar cuando la llamada ha terminado
  socket.on('callEnded', () => {
    console.log('La llamada ha terminado.');
    if (callStatus.value !== 'disconnect') {
      callStatus.value = 'disconnect';
      endCall();
    }
  });

  // Manejar cuando la llamada es rechazada
  socket.on('callRejected', () => {
    console.log('La llamada ha sido rechazada por el otro usuario.');
    if (callStatus.value !== 'disconnect') {
      callStatus.value = 'disconnect';
      endCall();
    }
  });

  // Manejar cuando el otro usuario se desconecta
  socket.on('userLeft', () => {
    console.log('El otro usuario ha dejado la llamada.');
    if (callStatus.value !== 'disconnect') {
      callStatus.value = 'disconnect';
      endCall();
    }
  });

  // Manejar cuando el socket se desconecta
  socket.on('disconnect', () => {
    console.log('Desconectado del servidor.');
    if (callStatus.value !== 'disconnect') {
      callStatus.value = 'disconnect';
      endCall();
    }
  });
}

// Inicializar conexión peer y configurar stream local
async function setupPeerConnection() {
  if (!peerConnection.value) {
    peerConnection.value = new RTCPeerConnection(iceServers);

    if (localStream.value) {
      localStream.value.getTracks().forEach((track) => {
        peerConnection.value.addTrack(track, localStream.value);
      });
    }

    peerConnection.value.ontrack = (event) => {
      if (remoteVideo.value) {
        remoteVideo.value.srcObject = event.streams[0];
      } else {
        console.error('Elemento de video remoto no está disponible');
      }
    };

    peerConnection.value.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit('ice-candidate', {
          roomId: room.value.uniqid,
          candidate: event.candidate,
        });
      }
    };
  } else {
    console.error('PeerConnection ya está inicializado.');
  }
}

// Iniciar el stream de video local
async function setupLocalStream() {
  try {
    localStream.value = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    if (localVideo.value) {
      localVideo.value.srcObject = localStream.value;
    }
  } catch (error) {
    console.error('Error al obtener el stream local:', error);
  }
}

// Método para rechazar la llamada
function rejectCall() {
  socket.emit('callRejected', { roomId: room.value.uniqid });
  callStatus.value = 'disconnect';
  endCall();
}

// Método para aceptar la llamada
async function acceptCall() {
  isCaller.value = false; // Establecer como receptor de la llamada
  callStatus.value = 'connected';
  await setupConnectedCall();
  socket.emit('callAccepted', { roomId: room.value.uniqid });
}

// Función para finalizar la llamada
function endCall() {
  // Cerrar la conexión peer
  if (peerConnection.value) {
    peerConnection.value.close();
    peerConnection.value = null;
  }

  // Detener todas las pistas en localStream
  if (localStream.value) {
    localStream.value.getTracks().forEach((track) => {
      track.stop();
    });
    localStream.value = null;
  }

  // Establecer srcObject de localVideo en null
  if (localVideo.value) {
    localVideo.value.srcObject = null;
  }

  // Detener todas las pistas en remoteStream
  if (remoteVideo.value && remoteVideo.value.srcObject) {
  remoteVideo.value.srcObject.getTracks().forEach((track) => {
    track.stop();
  });
  remoteVideo.value.srcObject = null;
}

  // Establecer srcObject de remoteVideo en null
  if (remoteVideo.value) {
    remoteVideo.value.srcObject = null;
  }

  // Remover listeners de eventos
  if (socket) {
    socket.off('offer');
    socket.off('answer');
    socket.off('ice-candidate');
    socket.off('callAccepted');
    socket.off('callRejected');
    socket.off('endCall');
    socket.off('callEnded');
    if (room.value) socket.emit('endCall', { roomId: room.value.uniqid });
  }

  // Restablecer estados
  callStatus.value = 'disconnect';
  webRTCListenersSetUp = false;
  callerData.value = {};
}

// Función para iniciar la llamada
function toggleCall() {
  if (callStatus.value === 'calling') {
    callStatus.value = 'disconnect';
    endCall();
  } else {
    callStatus.value = 'calling';
    initiateCall();
  }
}

// Establecer el estilo del botón de llamada
function callButtonStyle() {
  return callStatus.value === 'calling' || callStatus.value === 'connected'
    ? 'btn-danger'
    : 'btn-secondary';
}

async function initiateCall() {
  if (!socket) {
    console.log('Socket no está inicializado. Inicializando...');
    await initSocketIO();
  }

  // Notificar al otro usuario que estamos llamando
  socket.emit('callUser', {
    to: chatWith.value._id,
    roomId: room.value.uniqid,
    from: myself.value._id,
  });

  isCaller.value = true; // Establecer como iniciador de la llamada
  callStatus.value = 'calling';
}

// Manejar evento de finalizar llamada desde ConnectedCallView
function handleEndCall() {
  endCall();
}

// Crear y enviar la oferta
async function createOffer() {
  try {
    const offer = await peerConnection.value.createOffer();
    await peerConnection.value.setLocalDescription(offer);
    socket.emit('offer', { roomId: room.value.uniqid, offer });
  } catch (error) {
    console.error('Error creando la oferta:', error);
  }
}

// Manejar ofertas entrantes de WebRTC
async function handleOffer(offer) {
  if (!peerConnection.value) {
    await setupPeerConnection();
  }

  await peerConnection.value.setRemoteDescription(
    new RTCSessionDescription(offer)
  );
  const answer = await peerConnection.value.createAnswer();
  await peerConnection.value.setLocalDescription(answer);
  socket.emit('answer', { roomId: room.value.uniqid, answer });
}

// Manejar respuestas entrantes de WebRTC
async function handleAnswer(answer) {
  if (peerConnection.value) {
    await peerConnection.value.setRemoteDescription(
      new RTCSessionDescription(answer)
    );
  }
}

// Manejar intercambio de ICE candidates
async function handleICECandidate(candidate) {
  if (peerConnection.value && candidate) {
    await peerConnection.value.addIceCandidate(new RTCIceCandidate(candidate));
  }
}

function scrollToBottom() {
  // Esperar 100ms para que el DOM se actualice
  setTimeout(() => {
    if (messagesWindow.value) {
      messagesWindow.value.scrollTop = messagesWindow.value.scrollHeight;
    }
  }, 1);
}

function logout() {
  // Limpiar datos almacenados
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('token');
  localStorage.removeItem('myself');
  localStorage.removeItem('room');
  localStorage.removeItem('siteSectionActive');
  localStorage.removeItem('chatWith');

  // Reiniciar variables
  isLoggedIn.value = false;
  messages.value = [];
  newMessage.value = '';
  myself.value = null;
  chatWith.value = null;
  room.value = null;
  callStatus.value = 'disconnect';

  changeSection('home');

  // Desconectar Socket.IO
  if (socket) {
    socket.disconnect();
    socket = null;
  }
}

onBeforeMount(() => {
  i18n.global.locale = localStorage.getItem('language') || 'es';
  // Leer el primer elemento en el path
  const path = window.location.pathname.split('/');

  // Manejar cambio de sección
  if (path.length > 1) {
    if (path[1] === 'chatWith') {
      // Redireccionamos a home si hay una carga directa a chatWith
      window.location.href = '/';
    }
    changeSection(path[1]);
  } else {
    changeSection('home');
  }
});

onMounted(async () => {
  const token = localStorage.getItem('token');
  if (router.currentRoute.value.path === '/') {
    changeSection('home');
  }

  if (token) {
    isLoggedIn.value = true;
    myself.value = JSON.parse(localStorage.getItem('myself'));
    room.value = JSON.parse(localStorage.getItem('room'));
    switch (siteSectionActive.value) {
      case 'chatWith':
        // Manejar la lógica del chat cuando la sección es chatWith
        await initSocketIO();
        break;
      default:
        break;
    }
  } else {
    // Mostrar el diálogo de contraseña si no está autenticado
    isLoggedIn.value = false;
    changeSection('home');
  }
});

// Observa cambios en los mensajes para hacer scroll al fondo
watch(messages, () => {
  nextTick(() => {
    scrollToBottom();
  });
});

let roomId = null;
let response = null;
watch(siteSectionActive, async (newSection) => {
  // salir del room actual si no es chatWith
  if (newSection !== 'chatWith') {
    if (socket) {
      endCall();
      if (room.value) socket.emit('leaveRoom', room.value.uniqid);
    }
  }
  // Manejar la lógica del chat
  switch (newSection) {
    case 'chatWith':
      await initSocketIO();
      // Obtener el room usando myself y chatWith
      if (!myself.value && !chatWith.value) {
        changeSection('chats');
        return;
      }
      roomId = [myself.value._id, chatWith.value._id].sort().join('.');
      response = await fetch(`${config.apiHost}/api/rooms/${roomId}`, {
        headers: {
          Authorization: `Bearer ${myself.value.token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        room.value = data;

        localStorage.setItem('room', JSON.stringify(data));
      } else {
        console.error('Error obteniendo el room:', response.statusText);
      }

      // Añadir mensajes del room a messages
      messages.value = room.value.messages.reverse();
      socket.emit('joinRoom', room.value.uniqid);
      break;

    case 'logout':
      logout();
      break;

    default:
      break;
  }
});

// Manejo de callStatus en WebRTC
watch(callStatus, async (newStatus) => {
  if (newStatus === 'disconnect') {
    // Finalizar la llamada y limpiar recursos
    endCall();
  } else if (newStatus === 'calling') {
    // Iniciar la llamada saliente
    await initiateCall();
  } else if (newStatus === 'incomingCall') {
    // Estamos recibiendo una llamada
    console.log('Recibiendo una llamada entrante');
    // La vista de llamada entrante ya se maneja en el template
  } else if (newStatus === 'connected') {
    // Llamada conectada
    console.log('Llamada conectada');
    await setupConnectedCall();
  }
});

// Función para configurar la llamada una vez conectada
async function setupConnectedCall() {
  await setupLocalStream();
  await setupPeerConnection();

  // Solo el iniciador de la llamada crea y envía la oferta
  if (isCaller.value) {
    await createOffer();
  }
}

// Desconectar y limpiar al desmontar el componente
onBeforeUnmount(() => {
  if (peerConnection.value) {
    peerConnection.value.close();
    peerConnection.value = null;
  }
  if (localStream.value) {
    localStream.value.getTracks().forEach((track) => track.stop());
  }
  if (socket) {
    socket.off('callUser');
    socket.disconnect();
  }
});
</script>

<style>
#app {
  font-family: 'Arial', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
<style scoped>
/* app boxes */
header {
  background-color: #007bff;
  color: white;
  user-select: none;
}

main {
  flex-grow: 1;
  overflow-y: auto;
  user-select: none;
}

footer {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  color: #fff;
  padding: 10px;
  font-size: 0.85rem;
  user-select: none;
  text-align: center;
}
/* Views Containers */
.view-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; /* Para controlar el desbordamiento de la vista de mensajes */
  height: 100%;
}

.view-message {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
/* Video */
.video-window {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  overflow: hidden;
  background-color: rgba(0,0,0,0.8);
}

#remoteVideo {
  width: auto;
  height: 90vh;
  max-height: 90vh; /* Ajusta según el tamaño máximo deseado */
  max-width: 90vh; /* Ajusta según el tamaño máximo deseado */
  aspect-ratio: 9 / 16; /* Mantiene una relación de aspecto 16:9 */
  border: 1px solid #007bff; /* Opcional: añade un borde al video local */
  border-radius: 7px; /* Opcional: redondea las esquinas del video local */
  margin-left: 100px;
  object-fit: cover;
}

#localVideo {
  width: auto;
  height: auto;
  max-height: 300px; /* Ajusta según el tamaño máximo deseado */
  max-width: 180px; /* Ajusta según el tamaño máximo deseado */
  aspect-ratio: 9 / 16; /* Mantiene una relación de aspecto 16:9 */
  border: 1px solid #007bff; /* Opcional: añade un borde al video local */
  border-radius: 5px; /* Opcional: redondea las esquinas del video local */
  z-index: 1; /* Asegura que esté por encima del video remoto */
  object-fit: cover; /* Ajusta el video para llenar completamente el contenedor */
}

/* Estilos para la barra de navegación */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px;
  font-size: 0.85rem;
}

.navbar .user-initial {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: gray;
  color: white;
  text-align: center;
  line-height: 35px;
}

.navbar-brand {
  font-size: 1.5em;
  font-weight: bold;
}

.navbar-username {
  margin-right: 10px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-language {
  cursor: pointer;
  margin-right: 10px;
}
.navbar-links{
  color: white !important;
  cursor: pointer;
  margin-right: 10px;
}
.navbar-links:hover{
  color: white !important;
  text-decoration: underline;
}

/* Tus estilos existentes */
.chactCon{
  margin: 4px 4px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.91rem;
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.messages-window {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  z-index: 1;
  scrollbar-width: none;
  max-height: 80vh;
}

.input-bar {
  display: flex;
}

.input-bar input {
  margin-right: 5px;
}

.message {
  max-width: 60%;
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 0.85rem;
}

.message p {
  margin: 0;
  padding: 1px;
}

.sent {
  background-color: #d1e7dd;
  align-self: flex-end;
}

.received {
  background-color: #f8d7da;
  align-self: flex-start;
}

.message hr {
  border: 1px solid #888;
  margin: 1px 0 !important;
}

/* Estilos varios */
.opacity-gradient {
  position: relative;
  mask-image: none;
  -webkit-mask-image: none;
}

/* Ajustes para el nuevo botón de llamada */
.input-bar .btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Opcional: Cambiar el color del botón de llamada */
.btn-secondary {
  background-color: #28a745; /* Verde */
  border-color: #28a745;
}

.btn-secondary:hover {
  background-color: #218838;
  border-color: #1e7e34;
}

.half-size{
  max-height: 40vh;
}

/* ----------- Non-Retina Screens ----------- */
@media only screen and (max-width: 768px) { 
  .view-message{
    right: 0;
    z-index: 1;
    width: 100vw !important;
  }
  .video-window{
    display: block;
  }
  .opacity-gradient {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%); /* Para compatibilidad con WebKit */
  }
  #remoteVideo {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta el video para llenar completamente el contenedor */
    border: 0;
    border-radius: 0;
  }
  #localVideo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: auto;
    height: auto;
    max-height: 30vh; /* Ajusta según el tamaño máximo deseado */
    max-width: 112.5px; /* Ajusta según el tamaño máximo deseado */
    aspect-ratio: 9 / 16; /* Mantiene una relación de aspecto 16:9 */
    border: 1px solid #007bff; /* Opcional: añade un borde al video local */
    border-radius: 5px; /* Opcional: redondea las esquinas del video local */
    z-index: 1; /* Asegura que esté por encima del video remoto */
    object-fit: cover; /* Ajusta el video para llenar completamente el contenedor */
  }
}
/* Asegurar que las vistas de llamada estén por encima */
.incoming-call-view, .connected-call-view {
  z-index: 1000;
}
</style>
