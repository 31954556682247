<template>
    <div class="home-page">
        <div class="video-background">
            <video autoplay muted loop playsinline class="bg-video">
                <source src="/videos/bg.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
        <div class="welcome-container">
            <div style="display:flex; justify-content: center; align-items: center;">
                <img src="/logo.png" alt="Logo" />
                <h1>{{ $t('app_name') }} Me</h1>
            </div>
            <p class="welcome-text">Tu punto de partida para una experiencia asombrosa.</p>
            <button v-if="!isLoggedIn" @click="changeSection('login')" class="login-button">{{$t('login')}}</button>
            <button v-if="!isLoggedIn" @click="changeSection('register')" class="cancel-button">{{$t('register')}}</button>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';

const changeSection = inject('changeSection');
const isLoggedIn = inject('isLoggedIn');
</script>

<style scoped>
.home-page {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #fff;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
    padding: 10px
}

.welcome-container {
    text-align: center;
    padding: 20px;
    z-index: 2; /* Asegura que el contenido esté encima del video */
    position: relative;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #cfe0ff;
}

.welcome-text {
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.login-button, .cancel-button {
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px;
}

.login-button {
    background-color: #ffdc62;
    color: #333;
}

.login-button:hover {
    background-color: #ffd700;
}

.cancel-button {
    background-color: #d8d8d8;
    color: #333;
}

.cancel-button:hover {
    background-color: #aaaaaa;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Envía el video al fondo */
    overflow: hidden;
}

.bg-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(12px); /* Aplica el desenfoque */
}
</style>
