<template>
    <div class="password-dialog">
      <div class="overlay"></div>
      <div class="dialog-content">
        <h3>{{ $t('app_name','My Chat') }}</h3>
        <input v-model="username" type="text" class="form-control" :placeholder="$t('username', 'Username')" />
        <input v-model="password" type="password" class="form-control" :placeholder="$t('password', 'Password')" />
        <div v-if="!isLoggedIn" class="language-buttons">
          <button @click="authenticate()" class="btn btn-primary">{{ $t('login', 'Login') }}</button>
          <button @click="changeSection('home')" class="btn btn-default">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
    import { ref, defineEmits, inject } from 'vue'
    import config from '../config'

    const changeSection = inject('changeSection');
    const isLoggedIn = inject('isLoggedIn');

    const emit = defineEmits(['authenticated'])
    const username = ref('')
    const password = ref('')

    async function authenticate() {
    if (username.value.trim() === '' || password.value.trim() === '') {
        alert('Por favor, ingresa tu nombre de usuario y contraseña')
        return
    }

    try {
        const response = await fetch(`${config.apiHost}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username: username.value,
            password: password.value
        })
        })

        const data = await response.json()

        if (response.ok) {
          // Guardar datos de usuario
          localStorage.setItem('isLoggedIn', true)
          localStorage.setItem('token', data.token)
          localStorage.setItem('myself', JSON.stringify(data))
          localStorage.setItem('roomId',null)

          changeSection('chats')

          // Emitir evento de autenticación exitosa
          emit('authenticated', false)
        } else {
          localStorage.setItem('isLoggedIn', false)
          alert(data.message || 'Error al iniciar sesión')
        }
    } catch (error) {
        localStorage.setItem('isLoggedIn', false)
        console.error('Error al iniciar sesión:', error)
        alert('Error de red al intentar iniciar sesión')
    }
    }
  </script>

  
  <style>
  /* Tus estilos existentes */
  .password-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }
  
  .dialog-content {
    position: relative;
    top: 30%;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    width: 300px;
    border-radius: 10px;
    text-align: center;
  }
  
  .language-buttons {
    margin-top: 10px;
  }
  
  .language-buttons .btn {
    margin: 5px;
  }
  
  .form-control {
    margin-bottom: 10px;
  }
  </style>
  