import { createI18n } from 'vue-i18n'

const messages = {
  "en": {
    "welcome": "Welcome, {username}",
    "logout": "Log out",
    "send_message_placeholder": "Type a message...",
    "send_button": "Send",
    "app_name": "uiChat",
    "hello": "Hello,",
    "chatCon": "Chat with ",
    "cancel": "Cancel",
    "login": "Login",
    "register": "Register",
    "username": "Username",
    "password": "Password",
    "email": "Email",
    "firstName": "First Name",
    "lastName": "Last Name",
    "preferredLanguage": "Preferred Language",
    "username_placeholder": "Your username",
    "password_placeholder": "Your password (min. 6 characters)",
    "email_placeholder": "Your email address",
    "firstName_placeholder": "First name",
    "lastName_placeholder": "Last name",
    "label_preferredLanguage": "Choose your preferred language",
    "text_preferredLanguage": "Join to enjoy a personalized experience.",
    "text_footer": "Made with ❤️ by uiChat 2024"
  },
  "es": {
    "welcome": "Bienvenido, {username}",
    "logout": "Cerrar sesión",
    "send_message_placeholder": "Escribe un mensaje...",
    "send_button": "Enviar",
    "app_name": "uiChat",
    "hello": "Hola,",
    "chatCon": "Chatea con ",
    "cancel": "Cancelar",
    "login": "Iniciar sesión",
    "register": "Registrarse",
    "username": "Nombre de usuario",
    "password": "Contraseña",
    "email": "Correo electrónico",
    "firstName": "Nombre",
    "lastName": "Apellido",
    "preferredLanguage": "Idioma preferido",
    "username_placeholder": "Tu nombre de usuario",
    "password_placeholder": "Tu contraseña (mín. 6 caracteres)",
    "email_placeholder": "Tu dirección de correo",
    "firstName_placeholder": "Nombre",
    "lastName_placeholder": "Apellido",
    "label_preferredLanguage": "Selecciona tu idioma preferido",
    "text_preferredLanguage": "Únete para disfrutar de una experiencia personalizada.",
    "text_footer": "Hecho con ❤️ por uiChatme 2024"
},
  "th": {
    "welcome": "ยินดีต้อนรับ, {username}",
    "logout": "ออกจากระบบ",
    "send_message_placeholder": "พิมพ์ข้อความ...",
    "send_button": "ส่ง",
    "app_name": "uiChat",
    "hello": "สวัสดี,",
    "chatCon": "แชทกับ ",
    "cancel": "ยกเลิก",
    "login": "เข้าสู่ระบบ",
    "register": "สมัครสมาชิก",
    "username": "ชื่อผู้ใช้",
    "password": "รหัสผ่าน",
    "email": "อีเมล",
    "firstName": "ชื่อ",
    "lastName": "นามสกุล",
    "preferredLanguage": "ภาษาที่ต้องการ",
    "username_placeholder": "ชื่อผู้ใช้ของคุณ",
    "password_placeholder": "รหัสผ่านของคุณ (ขั้นต่ำ 6 ตัวอักษร)",
    "email_placeholder": "ที่อยู่อีเมลของคุณ",
    "firstName_placeholder": "ชื่อจริง",
    "lastName_placeholder": "นามสกุล",
    "label_preferredLanguage": "เลือกภาษาที่คุณต้องการ",
    "text_preferredLanguage": "เข้าร่วมเพื่อเพลิดเพลินกับประสบการณ์ที่ปรับให้เหมาะกับคุณ",
    "text_footer": "ทำด้วย ❤️ โดย uiChat 2024"
  },
  "fr": {
    "welcome": "Bienvenue, {username}",
    "logout": "Déconnexion",
    "send_message_placeholder": "Tapez un message...",
    "send_button": "Envoyer",
    "app_name": "uiChat",
    "hello": "Bonjour,",
    "chatCon": "Discutez avec "
  },
  "de": {
    "welcome": "Willkommen, {username}",
    "logout": "Ausloggen",
    "send_message_placeholder": "Nachricht eingeben...",
    "send_button": "Senden",
    "app_name": "uiChat",
    "hello": "Hallo,",
    "chatCon": "Chatten mit "
  },
  "zh": {
    "welcome": "欢迎, {username}",
    "logout": "登出",
    "send_message_placeholder": "输入消息...",
    "send_button": "发送",
    "app_name": "uiChat",
    "hello": "你好,",
    "chatCon": "聊天 "
  },
  "ja": {
    "welcome": "ようこそ, {username}",
    "logout": "ログアウト",
    "send_message_placeholder": "メッセージを入力...",
    "send_button": "送信",
    "app_name": "uiChat",
    "hello": "こんにちは,",
    "chatCon": "とチャット "
  },
  "ko": {
    "welcome": "환영합니다, {username}",
    "logout": "로그아웃",
    "send_message_placeholder": "메시지 입력...",
    "send_button": "보내기",
    "app_name": "uiChat",
    "hello": "안녕하세요,",
    "chatCon": "와 채팅 "
  },
  "it": {
    "welcome": "Benvenuto, {username}",
    "logout": "Disconnettersi",
    "send_message_placeholder": "Digita un messaggio...",
    "send_button": "Invia",
    "app_name": "uiChat",
    "hello": "Ciao,",
    "chatCon": "Chatta con "
  },
  "pt": {
    "welcome": "Bem-vindo, {username}",
    "logout": "Sair",
    "send_message_placeholder": "Digite uma mensagem...",
    "send_button": "Enviar",
    "app_name": "uiChat",
    "hello": "Olá,",
    "chatCon": "Converse com "
  },
  "ru": {
    "welcome": "Добро пожаловать, {username}",
    "logout": "Выйти",
    "send_message_placeholder": "Введите сообщение...",
    "send_button": "Отправить",
    "app_name": "uiChat",
    "hello": "Привет,",
    "chatCon": "Чат с "
  },
  "ar": {
    "welcome": "مرحباً, {username}",
    "logout": "تسجيل الخروج",
    "send_message_placeholder": "اكتب رسالة...",
    "send_button": "إرسال",
    "app_name": "uiChat",
    "hello": "مرحباً,",
    "chatCon": "الدردشة مع "
  },
  "hi": {
    "welcome": "स्वागत है, {username}",
    "logout": "लॉग आउट",
    "send_message_placeholder": "संदेश लिखें...",
    "send_button": "भेजें",
    "app_name": "uiChat",
    "hello": "नमस्ते,",
    "chatCon": "चैट करें "
  },
  "bn": {
    "welcome": "স্বাগতম, {username}",
    "logout": "লগ আউট",
    "send_message_placeholder": "একটি বার্তা টাইপ করুন...",
    "send_button": "পাঠান",
    "app_name": "uiChat",
    "hello": "হ্যালো,",
    "chatCon": "সঙ্গে চ্যাট "
  },
  "pa": {
    "welcome": "ਸੁਆਗਤ ਹੈ, {username}",
    "logout": "ਲੌਗ ਆਊਟ",
    "send_message_placeholder": "ਇੱਕ ਸੁਨੇਹਾ ਟਾਈਪ ਕਰੋ...",
    "send_button": "ਭੇਜੋ",
    "app_name": "uiChat",
    "hello": "ਸਤ ਸ੍ਰੀ ਅਕਾਲ,",
    "chatCon": "ਨਾਲ ਗੱਲਬਾਤ "
  },
  "jv": {
    "welcome": "Sugeng rawuh, {username}",
    "logout": "Metu",
    "send_message_placeholder": "Ketik pesen...",
    "send_button": "Kirim",
    "app_name": "uiChat",
    "hello": "Halo,",
    "chatCon": "Ngobrol karo "
  },
  "vi": {
    "welcome": "Chào mừng, {username}",
    "logout": "Đăng xuất",
    "send_message_placeholder": "Nhập tin nhắn...",
    "send_button": "Gửi",
    "app_name": "uiChat",
    "hello": "Xin chào,",
    "chatCon": "Trò chuyện với "
  },
  "id": {
    "welcome": "Selamat datang, {username}",
    "logout": "Keluar",
    "send_message_placeholder": "Ketik pesan...",
    "send_button": "Kirim",
    "app_name": "uiChat",
    "hello": "Halo,",
    "chatCon": "Obrolan dengan "
  },
  "mr": {
    "welcome": "स्वागत आहे, {username}",
    "logout": "लॉग आउट करा",
    "send_message_placeholder": "संदेश लिहा...",
    "send_button": "पाठवा",
    "app_name": "uiChat",
    "hello": "नमस्कार,",
    "chatCon": "सह गप्पा मारा "
  },
  "tr": {
    "welcome": "Hoş geldiniz, {username}",
    "logout": "Çıkış Yap",
    "send_message_placeholder": "Bir mesaj yazın...",
    "send_button": "Gönder",
    "app_name": "uiChat",
    "hello": "Merhaba,",
    "chatCon": "ile sohbet "
  },
  "ta": {
    "welcome": "வரவேற்கிறேன், {username}",
    "logout": "வெளியேறு",
    "send_message_placeholder": "ஒரு செய்தி தட்டச்சு செய்க...",
    "send_button": "அனுப்பு",
    "app_name": "uiChat",
    "hello": "வணக்கம்,",
    "chatCon": "இவருடன் அரட்டையடிக்க "
  },
  "ur": {
    "welcome": "خوش آمدید, {username}",
    "logout": "لاگ آوٹ",
    "send_message_placeholder": "ایک پیغام ٹائپ کریں...",
    "send_button": "بھیجیں",
    "app_name": "uiChat",
    "hello": "ہیلو,",
    "chatCon": "کے ساتھ چیٹ کریں "
  },
  "fa": {
    "welcome": "خوش آمدید, {username}",
    "logout": "خروج",
    "send_message_placeholder": "یک پیام تایپ کنید...",
    "send_button": "ارسال",
    "app_name": "uiChat",
    "hello": "سلام,",
    "chatCon": "چت با "
  },
  "ro": {
    "welcome": "Bun venit, {username}",
    "logout": "Deconectare",
    "send_message_placeholder": "Tastați un mesaj...",
    "send_button": "Trimite",
    "app_name": "uiChat",
    "hello": "Salut,",
    "chatCon": "Chat cu "
  },
  "pl": {
    "welcome": "Witamy, {username}",
    "logout": "Wyloguj się",
    "send_message_placeholder": "Wpisz wiadomość...",
    "send_button": "Wyślij",
    "app_name": "uiChat",
    "hello": "Cześć,",
    "chatCon": "Czat z "
  },
  "uk": {
    "welcome": "Ласкаво просимо, {username}",
    "logout": "Вийти",
    "send_message_placeholder": "Введіть повідомлення...",
    "send_button": "Надіслати",
    "app_name": "uiChat",
    "hello": "Привіт,",
    "chatCon": "Чат з "
  },
  "tl": {
    "welcome": "Maligayang pagdating, {username}",
    "logout": "Mag-logout",
    "send_message_placeholder": "Mag-type ng mensahe...",
    "send_button": "Ipadala",
    "app_name": "uiChat",
    "hello": "Kamusta,",
    "chatCon": "Makipag-chat kay "
  },
  "sv": {
    "welcome": "Välkommen, {username}",
    "logout": "Logga ut",
    "send_message_placeholder": "Skriv ett meddelande...",
    "send_button": "Skicka",
    "app_name": "uiChat",
    "hello": "Hej,",
    "chatCon": "Chatta med "
  },
  "nl": {
    "welcome": "Welkom, {username}",
    "logout": "Uitloggen",
    "send_message_placeholder": "Typ een bericht...",
    "send_button": "Verzenden",
    "app_name": "uiChat",
    "hello": "Hallo,",
    "chatCon": "Chatten met "
  }
}



const i18n = createI18n({
  locale: 'es', // Idioma por defecto
  fallbackLocale: 'en',
  messages
})

export default i18n
