<!-- ConnectedCallView.vue - Creado -->
<template>
  <div class="connected-call-view">
    <p>Llamada conectada</p>
    <button @click="endCall" class="btn btn-danger">Finalizar llamada</button>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['end'])

function endCall() {
  emit('end')
}
</script>

<style scoped>
.connected-call-view {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  z-index: 1000;
}
.connected-call-view button {
  margin-top: 5px;
}
</style>
