<!-- IncomingCallView.vue -->
<template>
  <div class="incoming-call-view">
    <p>{{ chatWithname }} está llamando...</p>
    <button @click="accept" class="btn btn-success">Aceptar</button>
    <button @click="reject" class="btn btn-danger">Rechazar</button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  chatWithname: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['accept', 'reject'])

function accept() {
  emit('accept')
}

function reject() {
  emit('reject')
}
</script>

<style scoped>
.incoming-call-view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
}
.incoming-call-view button {
  margin: 5px;
}
</style>
