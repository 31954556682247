<template>
    <div class="chats-container d-flex flex-wrap">
        <div class="chat-card" v-for="user in users" :key="user.id" @click="chatWithHandler(user._id)">
            <div class="avatar-container">
                <img :src="user.avatar || 'logo.png'" :alt="user.username" class="avatar" />
            </div>
            <div class="info-container">
                <h2>{{ user.username }}</h2>
                <span class="name">{{ user.firstName }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import axios from 'axios';
import config from '../config';

const chatWith = inject('chatWith');
const chanchangeSection = inject('changeSection');

const token = localStorage.getItem('token');
const users = ref([]);
const chatWithHandler = (userId) => {
    chatWith.value = users.value.find(user => user._id === userId);
    localStorage.setItem('chatWith', JSON.stringify(chatWith.value));
    chanchangeSection('chatWith');
};

const getUsers = async () => {
    try {
        const response = await axios.get(`${config.apiHost}/api/users`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        users.value = response.data;
    } catch (error) {
        console.error('Error al obtener la lista de personas:', error);
    }
};

getUsers();
</script>

<style scoped>
.chats-container {
    height: 100%;
    justify-content: space-evenly;
    align-content: space-evenly;
    overflow: auto;
}

.chat-card {
    position: relative;
    border-radius: 7px;
    background: linear-gradient(45deg, #6b11cb6c 0%, #2574fc67 100%);
    height: 37vh;
    width: 37vw;
    max-width: 180px;
    max-height: 320px;
    cursor: pointer;
    margin: 10px;
    overflow: hidden;
}

.avatar-container,
.info-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.avatar-container {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.info-container {
    padding: 10px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 0 0 7px 7px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 2;
}

.info-container h2 {
    margin: 0;
    font-size: 1.2em;
    text-align: center;
}

.info-container .name {
    font-size: 0.9em;
    text-align: center;
}
</style>
